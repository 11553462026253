/** @jsx jsx */
import { jsx, Grid, Box, Heading, Flex } from 'theme-ui'
import { FC, Fragment } from 'react'
import { CollectionTemplateType } from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'
import { space } from '~/gatsby-plugin-theme-ui'
import ProductItemFabrics from '~/components/product-item-fabrics'
import { graphql, useStaticQuery } from 'gatsby'
import HeadingV2 from '~/componentsV2/heading'
import { CollectionProvider } from '~/context/collection'

type Props = CollectionTemplateType

const query = graphql`
  query {
    allShopifyCollection(
      filter: {
        handle: {
          in: [
            "luxury-fabric"
            "belgian-linen"
            "performance-fabric"
            "textured-fabrics"
            "outdoor-fabric"
            "wool-fabrics"
          ]
        }
      }
      sort: { fields: metafields___rugcollectionlinkorder, order: ASC }
    ) {
      edges {
        node {
          id
          handle
          title
          metafields {
            collectionCustomImageUrl
          }
          image {
            originalSrc
          }
          products {
            handle
          }
          seo {
            title
            description
          }
        }
      }
    }
  }
`

interface QueryResult {
  allShopifyCollection: {
    edges: Array<{
      node: {
        id: string
        handle: string
        title: string
        metafields: {
          collectionCustomImageUrl: string
        }
        image: {
          originalSrc: string
        }
        products: Array<{
          handle: string
        }>
        seo: {
          title: string
          description: string
        }
      }
    }>
  }
}

const CollectionFabricSwatches: FC<Props> = ({ pageContext: { data } }) => {
  const textiles = data.handle === 'textiles'
  const featuredCollectionsList = useStaticQuery<QueryResult>(query).allShopifyCollection.edges

  const textileProducts = [
    'alpaca-fabric',
    'classic-linen-fabric',
    'velvet-fabric',
    'distressed-textured-weave',
    'outdoor-herringbone-fabric',
    'wool-flannel',
  ]

  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      seoDescription={data.seo?.description || data.description}
      description={data.description}
      ankleContent={data.metafields?.ankleContent}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      headerDescription={data.metafields?.headerDescription}
      showFeaturedCollections={textiles}
      handle={data.handle}
      featuredCollectionsList={featuredCollectionsList}
    >
      <CollectionProvider collectionHandle={data.handle}>
        <Flex
          className={`collection-products fabric-swatches ${textiles ? 'textiles' : ''}`}
          sx={{
            flexDirection: 'column',
            marginTop: [space.md1, null],
            ...(data.handle === 'new-arrivals' && {
              '.ProductLabel--New': {
                display: 'none',
              },
            }),
          }}
        >
          <Flex
            sx={{
              flexDirection: ['column', null, null, 'row'],
              width: '100%',
              gap: [0, null, space.md1],
              maxWidth: '100%',
              '.infinite-scroll-component__outerdiv': {
                flex: 1,
              },
            }}
          >
            <Grid columns={[1]} gap={[space.md, null, space.gridGap2]}>
              {data.products.map(item => {
                const found = featuredCollectionsList.find(collection => {
                  return collection.node.products.find(product => product.handle === item.handle)
                })
                return textileProducts.includes(item.handle) && textiles === true ? (
                  <Fragment>
                    <HeadingV2
                      sx={{
                        fontSize: ['28px !important', '32px !important'],
                        textAlign: 'initial',
                        mt: [space.md3],
                        textTransform: 'uppercase',
                      }}
                    >
                      {found?.node.title}
                    </HeadingV2>
                    <Box key={item.id}>
                      <ProductItemFabrics product={item} />
                    </Box>
                  </Fragment>
                ) : (
                  <Box key={item.id}>
                    <ProductItemFabrics product={item} />
                  </Box>
                )
              })}
            </Grid>
          </Flex>
        </Flex>
      </CollectionProvider>
    </CollectionLayout>
  ) as JSX.Element
}

export default CollectionFabricSwatches
